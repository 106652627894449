import {Option} from "fp-ts/Option";
import {briefcase, cog, home, key, qrCode} from "ionicons/icons"; // https://ionic.io/ionicons/v4
import {FC} from "react";
import {CompanySelectPage} from "../features/CompanySeletions/CompanySelectPage";
import {EventScannerPage} from "../features/EventScanner/EventScannerPage";
import {HomePage} from "../features/Home/HomePage";
import {LoginPage} from "../features/Login/LoginPage";
import {SettingsPage} from "../features/Settings/SettingsPage";
import {EnumDictionary} from "./ticketsCore.Tooling";

// Just a way for us to show and hide menu items base on other data. Add mode stuff.  
export type OptionalPropertiesForMenuRendering
= {
    companyCountForUser:number
}

export enum PageName {
    events = "Events",
    settings = "Settings",
    login = "Login",
    //checkIn = "CheckIn",
    // counter = "Counter",
    // pingPong = "PingPong",
    selectCompany = "Select Company",
    eventScanner = "Event Scanner",
    //logOut = "Logout"
}

interface PageSettingsBase {
    isSecure: boolean,
    url: string,
    iosIcon: string,
    mdIcon: string,
    title: string,
    showIfLoggedOn: boolean
    showIfNotLoggedOn: boolean,
    defaultPageTemplate:boolean,
    developerModeOnly:boolean,
    optionalDisplayFunction:(s:OptionalPropertiesForMenuRendering)=>boolean 
}

interface IPageSettings extends PageSettingsBase {
    pageName: PageName,
    $Template: FC<{}> // a non alpha first character lets you render it in JSX https://gist.github.com/mikeyamadeo/6bdbbfde7ff0e1c3cf3c 
}

//const defaultPage: Pick<PageSettings, 'requireLogin' | 'title'>  = {requireLogin:true, title:'blah'}  // demo for using multiple keys  
type partialPages = 'isSecure' | 'showIfLoggedOn' | 'showIfNotLoggedOn' | 'defaultPageTemplate' | 'developerModeOnly' | 'optionalDisplayFunction'
const defaultSecurePage: Pick<IPageSettings, partialPages> = {isSecure: true, showIfLoggedOn:true, showIfNotLoggedOn:false, defaultPageTemplate:true, developerModeOnly:false, optionalDisplayFunction:() => true}
const defaultUnsecuredPage: Pick<IPageSettings, partialPages> = {isSecure: false, showIfLoggedOn:true, showIfNotLoggedOn:true, defaultPageTemplate:true, developerModeOnly:false, optionalDisplayFunction:()=>true}

export const PageSettings: EnumDictionary<PageName, IPageSettings> = {
    [PageName.login]: {...defaultUnsecuredPage, pageName: PageName.login, $Template: LoginPage, url:'/page/Login', iosIcon:key, mdIcon:key ,title:'Login', showIfLoggedOn:false},
    [PageName.settings]: {...defaultUnsecuredPage,...{pageName: PageName.settings, $Template: SettingsPage, url:'/page/Settings', iosIcon:cog, mdIcon:cog ,title:'Settings', developerModeOnly:true} },
    [PageName.events]: {...defaultSecurePage, ...{pageName: PageName.events, $Template: HomePage, url:'/page/Home', iosIcon:home, mdIcon:home ,title:'Events', defaultPageTemplate:false}},
    [PageName.selectCompany]: {...defaultSecurePage,...{pageName: PageName.selectCompany, $Template: CompanySelectPage, url:'/page/Company', iosIcon:briefcase, mdIcon:briefcase ,title:'Select Company'}, optionalDisplayFunction:(x)=>x.companyCountForUser > 1},
    [PageName.eventScanner]: {...defaultSecurePage,...{pageName: PageName.eventScanner, $Template: EventScannerPage, url:'/page/EventScanner', iosIcon:qrCode, mdIcon:qrCode ,title:'Scanner', developerModeOnly:true}},
    //[PageName.checkIn]: {...defaultSecurePage,...{pageName: PageName.checkIn, $Template: () => CheckIn({eventId:''}), url:'/page/Checkin/:eventId', iosIcon:briefcase, mdIcon:briefcase ,title:'Check In'}}
    // [PageName.counter]: {...defaultSecurePage,...{pageName: PageName.counter, $Template: Counter, url:'/page/Counter', iosIcon:calendarNumber, mdIcon:calendarNumber ,title:'Counter'}},
    // [PageName.pingPong]: { ...defaultSecurePage...{pageName: PageName.pingPong, $Template: PingPong, url:'/page/PingPong', iosIcon:calendarNumber, mdIcon:calendarNumber ,title:'Ping Pong'}},
}

