import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonIcon, IonLabel, IonProgressBar, IonRippleEffect, IonRow} from '@ionic/react';
import {some} from "fp-ts/Option";
import {checkboxOutline, squareOutline} from "ionicons/icons";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {SearchTicketCheckInModel} from "../../data/ticketsAPI/Models/EventModels";
import {AxiosRequest$} from "../../data/user/tickets-auth-api";
import {CheckInTicket} from "../../data/user/tickets-http-requests";
import './CheckInCard.css'

type checkinCardProps = { person: SearchTicketCheckInModel, activeEnvironment: EnvironmentSettings }
export const CheckInCard2 = ({person, activeEnvironment}: checkinCardProps) => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isCheckedIn, setIsCheckedIn] = useState(person.checkedIn)
    const dispatch = useAppDispatch()
    const processClick = (p: SearchTicketCheckInModel) => {
        if (isLoading) return
        setIsLoading(true)
        AxiosRequest$(activeEnvironment, {ticketId: person.ticketId, checkedIn: !isCheckedIn}, CheckInTicket, some(dispatch)).subscribe({
            next: x => {
                setIsLoading(false)
                setIsCheckedIn(x => !x)
            }, error: () => {
                setIsLoading(false)
            }, complete: () => {
            }
        })
    }
    return <>
        <IonCard className={isCheckedIn ? "marginBottom hover checked-in" : "marginBottom hover not-checked-in"} margin-top key={person.ticketId}
                 onClick={() => {
                     processClick(person)
                 }}>
            <IonCardContent>
                <IonRow style={{margin:'0px'}}>
                    <IonCol size="8">
                        <h3>{person.displayString} - {person.ticketNumber}</h3>
                    </IonCol>

                    <IonCol size="3">
                        <IonLabel text-uppercase>{person.ticketCancelled ? 'Cancelled' : ''}</IonLabel>
                    </IonCol>

                    <IonCol size="1" >
                        {isCheckedIn ? <IonIcon className='checked-in ion-align-self-end' size="large" icon={checkboxOutline}/> : <IonIcon className='not-checked-in' size="large" icon={squareOutline}/>}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <span>{person.ticketTypeName}</span>
                    </IonCol>
                </IonRow>
                {isLoading && <IonProgressBar type="indeterminate"/>}
            </IonCardContent>
        </IonCard>

    </>
}