import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fromNullable, none, Option} from "fp-ts/Option";
import {ofType, StateObservable} from "redux-observable";
import * as rxjs from "rxjs";
import {from, Observable} from "rxjs";
import {catchError, endWith, map, mapTo, mergeMapTo, startWith, switchMap, tap} from "rxjs/operators";
import {RootState} from "../../app/store";
import {processUserDetailsFromServer, setCompanyId, setDarkMode, setEnvironment, stateRetrievedFromStorage, StoredData} from "../CommonActions/SettingsAndStorageActions";
import {requestShortCodeToEmail} from "../Login/LoginSlice";
import {storageKey} from "./../../app/ticketsCore";
import * as core from './../../app/ticketsCore'


export interface StorageState {
    storageInitialised: boolean
    savingState: boolean
    loadingState: boolean
}


export const initialState: StorageState = {
    storageInitialised: false,
    savingState: false,
    loadingState: false,
};

export const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {

        storageError: (state, a: PayloadAction<string>) => {
            state = {...initialState}
        },
        savingState: (state) => {
            state.savingState = true
        },
        stateSaved: (state) => {
            state.savingState = false
        },
        loadingState: (state) => {
            state.loadingState = true
        },
        stateLoaded: (state) => {
            state.loadingState = false
        },
        flushStateToStorage: (state) => {
        },
        loadStateFromStorage: (state) => {
        }
    },
    extraReducers: (builder) => {
        builder.addCase(stateRetrievedFromStorage, (state, action: PayloadAction<Option<StoredData>>) => {
            state.storageInitialised = true;
        })
    }
})

export const saveStateToStorageEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
    action$.pipe(ofType(
        setEnvironment.toString(),
        setDarkMode.toString(),
        processUserDetailsFromServer.toString(),
        setCompanyId.toString(),
        requestShortCodeToEmail.toString()
    ), //mapTo(flushStateToStorage())) // note that mapTo differs to Map. It always yields the same. 
        mergeMapTo([flushStateToStorage()]))

export const trackSettingsChangesEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(flushStateToStorage),
        switchMap(x =>
            from(core.Singleton.storage.set(storageKey,
                {
                    userDetails: state$.value.loginSlice.userDetails,
                    selectedCompany: state$.value.loginSlice.selectedCompaniesPerEnvironment,
                    environment: state$.value.loginSlice.activeEnvironment.environment,
                    darkMode: state$.value.settingsSlice.darkMode,
                    recentlyTriedEmails:state$.value.loginSlice.recentlyTriedEmails || []
                } as StoredData
            ))
                .pipe(
                    mapTo(stateSaved()),
                    startWith(savingState()),

                    catchError(error => rxjs.of(storageError(error.messages))),
                ),
        )
    )

export const loadStateFromStorageEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(loadStateFromStorage),
        switchMap(x =>
            from(core.Singleton.storage.get(storageKey))
                .pipe(
                    //tap(t => console.log('GOT FROM DB', t)),
                    map((i: StoredData) => stateRetrievedFromStorage(fromNullable(i))),
                    startWith(loadingState()),
                    endWith(stateLoaded()),
                    catchError(error => rxjs.of(storageError(error.messages)))
                )
        ))


export const {savingState, stateSaved, flushStateToStorage, loadStateFromStorage, loadingState, stateLoaded, storageError} = storageSlice.actions;
export const epics = [saveStateToStorageEpic, trackSettingsChangesEpic, loadStateFromStorageEpic]
export default storageSlice.reducer;
//export {}