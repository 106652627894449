import {createAction, PayloadAction} from "@reduxjs/toolkit";
import {Environment} from "../../app/environmentFunctions";
import {fromNullable, match, none, Option} from "fp-ts/Option";
import {pipe, pipe as fptsPipe} from "fp-ts/function";
import {EnumDictionary, EnvironmentFunctions} from "../../app/ticketsCore.Tooling";
import {UserDetails} from "../../data/ticketsAPI/Models/userDetails";
import * as O from 'fp-ts/Option'
export type StoredData = {
    environment: Environment,
    darkMode: darkModeValues,
    selectedCompany: DictionaryOfOptions<string>
    userDetails: EnumDictionary<Environment, Option<UserDetails>>,
    recentlyTriedEmails:string[] // will be limitedArrayProxy (search for that)
}

//export type DictionaryOfOptionalUserDetails = EnumDictionary<Environment, Option<UserDetails>>
export type DictionaryOfOptions<T> = EnumDictionary<Environment, Option<T>>

export const extractBearerFromUserDetails = (details:Option<UserDetails>) => pipe(details, O.map(pd => pd.bearerToken))  
export type darkModeValues = 'light' | 'dark'

export const stateRetrievedFromStorage = createAction<Option<StoredData>>('STATE_RETRIEVED_FROM_STORAGE')
export const setEnvironment = createAction<Environment>('SET_ENVIRONMENT')
export const setCompanyId = createAction<{ companyId:Option<string>}>('SET_COMPANY_ID')
export const setDarkMode = createAction<darkModeValues>('SET_DARK_MODE')

export const processUserDetailsFromServer = createAction<{ userData: Option<UserDetails>, environment: Environment }>('PROCESS_USER_DETAILS_FROM_SERVER')

