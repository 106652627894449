import {IonButton} from "@ionic/react";
import React, {useState} from "react";
import {useAppSelector} from "../../app/hooks";

declare let window: any;
type checkinPageProps = {}
export const EventScannerPage = () => {
    const activeEnvironment = useAppSelector(x => x.loginSlice.activeEnvironment)
    const [result, setResult] = useState('')
    const [format, setFormat] = useState('')
    const [cancelled, setCancelled] = useState('')
    const [showScanner, setShowScanner] = useState(false)
    if (showScanner) {
        window.cordova.plugins.barcodeScanner.scan(
            (result: any) => {
                console.log(result)
                setShowScanner(false)
                setResult(result.text)
                setFormat(result.format)
                setCancelled(result.cancelled)

            },
            (err: any) => console.error(err),
            {
                //preferFrontCamera: true, // iOS and Android
                showFlipCameraButton: true, // iOS and Android
                showTorchButton: true, // iOS and Android
                torchOn: false, // Android, launch with the torch switched on (if available)
                saveHistory: true, // Android, save scan history (default false)
                prompt: "Place a barcode inside the scan area", // Android
                resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                //formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
                //formats:'all',
                orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                disableAnimations: true, // iOS
                disableSuccessBeep: false // iOS and Android
            }
        )
    }

    return <>
        <div>Event Scanner</div>
        <h1>{result}</h1>
        <h1>{format}</h1>
        <IonButton onClick={() => setShowScanner(true)}>Show Scanner</IonButton>
    </>
}

/*

https://capacitorjs.com/docs/v2/cordova/known-incompatible-plugins
https://github.com/ionic-team/capacitor/issues/1213

looks like you have to use a PhoneGap Scanner
https://github.com/ionic-team/capacitor/issues/1213#issuecomment-461858292

Read this. 
https://enappd.com/blog/qr-code-and-barcode-scanning-with-ionic-capacitor/127/


 */
