//import {appPages} from "./AppPages";
import {menuController} from '@ionic/core'
import {getConfig, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonNote, IonToggle,} from '@ionic/react';
import {pipe} from "fp-ts/function";
import * as O from "fp-ts/Option";
import {moonOutline} from 'ionicons/icons';
import React from "react";
import {useLocation} from 'react-router-dom';
import {$enum} from "ts-enum-util";
import {filter} from 'lodash'
import {useAppDispatch, useAppSelector} from '../app/hooks'
import {OptionalPropertiesForMenuRendering, PageName, PageSettings} from "../app/ticketsCore.pageSettings"
import {EnvironmentSettings} from "../app/ticketsCore.Tooling";
import {setDarkMode} from "../features/CommonActions/SettingsAndStorageActions";
import {tapLogoForSecretCode} from "../features/Login/LoginSlice";
import {Logout} from "../features/Login/Logout";
import {CompanySelector} from "../features/Settings/CompanySelector";
import './Menu.css';
import {phoneModes} from "../features/Settings/settingsSlice";

export const LoggedInEmailComponent = () => {
    const userDetails = useAppSelector(x => x.loginSlice.activeUserDetails)
    const email = pipe(userDetails, O.match(() => 'Not Logged In', a => a.email))
    return <IonNote>{email}</IonNote>
}

export const SelectedCompanyComponent = () => {
    
    const activeUserDetails = useAppSelector(x => x.loginSlice.activeUserDetails)
    const companies = pipe(activeUserDetails, O.match(() => [], ud => ud.companyDetails))
    const selectedCompany = useAppSelector(x => pipe(x.loginSlice.activeEnvironment.selectedCompany, O.match(() => '', x2 => filter(companies, x=>x.companyId == x2)[0]?.companyName ?? 'not found')))
    return <IonNote>{selectedCompany}</IonNote>
}

type MenuProps = { isLoggedIn: boolean, environment:EnvironmentSettings }
const Menu = ({isLoggedIn, environment}: MenuProps) => {
    const location = useLocation();
    const darkMode = useAppSelector(x => x.settingsSlice.darkMode)
    const companyCount =useAppSelector(x => x.loginSlice.companyCountForUser)
    let mode = getConfig()?.get('mode') || 'md' as phoneModes
    const isDarkModeEnabled = () => darkMode === 'dark'
    const dispatch = useAppDispatch();
    const renderCheckProp = {companyCountForUser:companyCount} as OptionalPropertiesForMenuRendering
    const toggleDarkMode = () => {
        menuController.close().then()
        isDarkModeEnabled() ? dispatch(setDarkMode('light')) : dispatch(setDarkMode('dark'))
    }
    const isDeveloperMode = useAppSelector(x=>x.loginSlice.developerModeEnabled)
    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonItem>
                    <IonImg className="tickets-logo" slot={'start'} src="assets/tickets-logo-colour-rgb.png" onClick={() => dispatch(tapLogoForSecretCode())} />
                </IonItem>

                {/* I took this out so that the menu didn't auto close when I clicked the logout button <IonMenuToggle autoHide={false}>*/}

                <IonList id="menu-list">
                    <p><LoggedInEmailComponent /></p>
                    <p><SelectedCompanyComponent /></p>
                    {/*<CompanySelector />*/}
                    {$enum(PageName)
                        .map(x => PageSettings[x])
                        .filter(x => ((isLoggedIn && x.showIfLoggedOn) 
                                        || (!isLoggedIn && x.showIfNotLoggedOn))
                                    && (!x.developerModeOnly || isDeveloperMode)
                                    && x.optionalDisplayFunction(renderCheckProp)
                        )
                        .map((s, index) =>
                            <IonItem key={s.url} className={location.pathname === s.url ? 'selected' : ''}
                                     routerLink={s.url}
                                     routerDirection="none"
                                     lines="none" detail={false}
                                     onClick={() => menuController.close()}
                            >
                                <IonIcon slot="start" ios={s.iosIcon} md={s.mdIcon}/>
                                <IonLabel>{`${s.title}`}</IonLabel>
                            </IonItem>
                        )}

                    <IonItem lines="none"/>
                    {/*Hook into the dark mode OS level setting on IOS. See app.tsx and https://ionicframework.com/docs/theming/dark-mode*/}
                    {mode == 'md' && <IonItem lines="none">
                        <IonIcon slot="start" icon={moonOutline}/>
                        <IonLabel onClick={() => toggleDarkMode()}>Dark Mode</IonLabel>
                        <IonToggle content-id='mytoggle' checked={isDarkModeEnabled()} onClick={() => toggleDarkMode()}/>
                    </IonItem>}

                    {isLoggedIn && <Logout menuController={menuController}/>}


                </IonList>
                {/*</IonMenuToggle>*/}

            </IonContent>
        </IonMenu>
    )
        ;
};

export default Menu;


