import {ScrollDetail, SearchbarChangeEventDetail} from "@ionic/core";
import {IonContent, IonHeader, IonProgressBar, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar} from "@ionic/react";
import {isSome, none, Option} from "fp-ts/Option";
import {debounce} from 'lodash'
import React, {useRef, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {PageName, PageSettings} from "../../app/ticketsCore.pageSettings";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {EventHeaderModel, SearchEventsParams} from "../../data/ticketsAPI/Models/EventModels";
import {LargeCard} from "../Events/LargeCard";
import {useEventHeaderData} from "../Events/useEventHeaderDataComponent";
import './EventList.css'

// https://upmostly.com/tutorials/build-an-infinite-scroll-component-in-react-using-react-hooks
// https://www.digitalocean.com/community/tutorials/react-react-infinite-scroll
type eventListProps = { activeEnvironment: EnvironmentSettings }

export enum EventPageModes {
    Current = "Current",
    Archived = 'Archived',
    All = 'All'
}

type displayModes = EventPageModes.Current | EventPageModes.All | EventPageModes.Archived

export const EventList = ({activeEnvironment}: eventListProps) => {
    const pageSize = 10
    const searchMode = (s: displayModes): { showActive: boolean, showArchived: boolean } => (
        s === EventPageModes.All
            ? {showActive: true, showArchived: true}
            : s === EventPageModes.Current
            ? {showActive: true, showArchived: false}
            : {showActive: false, showArchived: true})
    const [segment, setSegment] = useState<displayModes>(EventPageModes.Current);
    const ios = useAppSelector(x => x.settingsSlice.mode) === 'ios';
    const searchParams: SearchEventsParams = {"pageSize": pageSize, "pageIndex": 1, "searchTerm": "", ...searchMode(segment)}
    const [requestPayload, setRequestPayload] = useState(searchParams)
    const [lastPageLoaded, setLastPageLoaded] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    //const [searchText, setSearchText] = useState('')
    const [allLoaded, setAllLoaded] = useState(false)
    const [error, setError] = useState<Option<string>>(none)
    const [responseEventHeaders, setResponseEventHeaders] = useState<EventHeaderModel[]>([])

    const content = useRef<HTMLIonContentElement>(null) //https://linguinecode.com/post/how-to-use-react-useref-with-typescript
    const eventPage = PageSettings[PageName.events]
    let a = useEventHeaderData({params: requestPayload, setResponseEventHeaders, setLastPageLoaded, setIsLoading, setAllLoaded, setError, activeEnvironment, error})


    function reset() {
        setResponseEventHeaders([])
        setIsLoading(true)
        setLastPageLoaded(1)
        setAllLoaded(false)
    }

    let searchText = (e: CustomEvent<SearchbarChangeEventDetail>) => {
        reset()
        setRequestPayload(e2 => ({...e2, searchTerm: e.detail.value!, pageIndex: 1}))
    }

    const trackScrollInternal = debounce(async (e: CustomEvent<ScrollDetail>) => {
        //https://stackoverflow.com/questions/61886891/how-to-get-maximum-scroll-height-for-ion-content
        const elem = content.current!
        const scrollElement = await (elem as any).getScrollElement()
        const scrollPosition = e.detail.scrollTop;
        const totalContentHeight = scrollElement.scrollHeight;
        const viewportHeight = elem.getBoundingClientRect().top + elem.offsetHeight; // I think that elem.ClientHeight returns the same
        const percentage = scrollPosition / (totalContentHeight - viewportHeight);
        const distanceFromBottom = (totalContentHeight - viewportHeight) - scrollPosition

        if (distanceFromBottom < 1000 && lastPageLoaded === requestPayload.pageIndex && !allLoaded) {
            setIsLoading(true)
            setRequestPayload(e => ({...e, pageIndex: e.pageIndex + 1}))
        }
    }, 100) 


    return <>
        <IonToolbar>
            <IonSegment value={segment} onIonChange={(e) => {
                reset()
                setSegment(e.detail.value as any)
                setRequestPayload(e2 => ({...e2, pageIndex: 1, ...searchMode(e.detail.value as EventPageModes)}))
            }}>
                <IonSegmentButton value={EventPageModes.Current}>
                    {EventPageModes.Current}
                </IonSegmentButton>
                <IonSegmentButton value={EventPageModes.All}>
                    {EventPageModes.All}
                </IonSegmentButton>
                <IonSegmentButton value={EventPageModes.Archived}>
                    {EventPageModes.Archived}
                </IonSegmentButton>
            </IonSegment>
        </IonToolbar>


        <IonSearchbar className='no-padding' onIonChange={e => searchText(e)}/>
        {/*<IonLabel>{`${lastPageLoaded} ${requestPayload.pageIndex} ${requestPayload.searchTerm} ${allLoaded}`}</IonLabel>*/}
        <IonContent ref={content} scrollEvents={true} onIonScroll={(e) => trackScrollInternal(e)} className="event-content">
            <IonHeader collapse="condense">
                <IonToolbar>
                    <IonTitle size="large">{eventPage.title}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <div className="ion-padding">
                {isSome(error)
                    ? <div>{error.value}</div>
                    : (<>
                        {responseEventHeaders.map(e => (<LargeCard key={`${activeEnvironment.selectedCompany}_${e.id}`} event={e}/>))}
                        {isLoading && <IonProgressBar type="indeterminate"/>}

                    </>)}
            </div>
        </IonContent>
    </>
}
