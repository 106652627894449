import {getConfig} from '@ionic/react'
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {isSome, Option} from "fp-ts/Option";
import {Environment, GetEnvironmentSettings, initialEnvironment} from "../../app/environmentFunctions";
import {EnvironmentFunctions, EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {darkModeValues, setDarkMode, setEnvironment, stateRetrievedFromStorage, StoredData} from "../CommonActions/SettingsAndStorageActions";

export type phoneModes = 'ios' | 'md'

export interface SettingsState {
    selectedEnvironment: Environment,
    darkMode: darkModeValues,
    mode: phoneModes
}

export const initialState: SettingsState = {
    selectedEnvironment: initialEnvironment,
    darkMode: "dark",
    mode: 'md'
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setPhoneMode: (state, action: PayloadAction<phoneModes>) => {
            state.mode = action.payload
        },
    },

    extraReducers: (builder) => {

        builder.addCase(setDarkMode, (state, action: PayloadAction<darkModeValues>) => {
            state.darkMode = action.payload
        })

        builder.addCase(setEnvironment, (state, action: PayloadAction<Environment>) => {
            state.selectedEnvironment = action.payload
        })

        builder.addCase(stateRetrievedFromStorage, (state, action: PayloadAction<Option<StoredData>>) => {
            if (isSome(action.payload)) {
                let ap = action.payload.value
                state.darkMode = ap.darkMode
                state.selectedEnvironment = ap.environment
            } else
                state = {...initialState}
        })
    }
});

// Export the actionCreators
export const {setPhoneMode} = settingsSlice.actions;
// export const epics = [pingEpic, pongEpic]

// export the reducer
export default settingsSlice.reducer;
