import {IonButton, IonCard, IonCardContent, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonProgressBar, IonRow, IonText, IonTitle, IonToolbar} from "@ionic/react";
import {isNone, isSome, none, Option, some} from "fp-ts/Option";
import {checkboxOutline, scan, search, squareOutline} from "ionicons/icons";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {BehaviorSubject} from "rxjs";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {CheckInOrderModel} from "../../data/ticketsAPI/Models/CheckInOrderModels";
import {SearchTicketCheckInModel} from "../../data/ticketsAPI/Models/EventModels";
import {AxiosRequest$} from "../../data/user/tickets-auth-api";
import {GetCheckinOrderFromOrderNumber, GetCheckinOrderFromQrCode} from "../../data/user/tickets-http-requests";
import {CheckInCard} from "./CheckInCard";
import {CheckInCard2} from "./CheckInCard2";

declare let window: any;

export enum CheckInScannerPageMode {
    ShowingOrder = "ShowingOrder",
    NotShowingOrder = 'NotShowingOrder'
}


type ErrorLabelRef = {
    setVisible: (status: boolean) => void
    setText: (text: string) => void
} | null;

type ErrorLabelProps = {
    children?: React.ReactNode | null;
};


// https://stackoverflow.com/a/55889638/494635
// https://www.tutorialspoint.com/reactjs-useimperativehandle-hook
// Need to do this so that we can render only the child component without rendering the parent. 
const OrderNumberErrorLabel = forwardRef<ErrorLabelRef, ErrorLabelProps>((props, ref) => {
    const [visible, _setVisible] = useState(false);
    const [text, _setText] = useState('');

    useImperativeHandle(ref, () => {
        return {
            setVisible: _setVisible,
            setText: _setText
        }
    });
    return visible ? <IonText color="danger">{text}</IonText> : <IonText>&nbsp;</IonText>
});



type checkinProps = { eventId: string, activeEnvironment: EnvironmentSettings }
type displayModes = CheckInScannerPageMode.ShowingOrder | CheckInScannerPageMode.NotShowingOrder
export const CheckInScanner = ({eventId, activeEnvironment}: checkinProps) => {
    const isDeveloperMode = useAppSelector(x=>x.loginSlice.developerModeEnabled)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [globalError, setGlobalError] = useState<Option<string>>(none)
    const [orderSearchError, setOrderSearchError] = useState<Option<string>>(none) // because order text fires http on type, have a special error display. 
    const dispatch = useAppDispatch()
    const [orderModel, setOrderModel] = useState<Option<CheckInOrderModel>>(none);
    const [orderNumber, setOrderNumber] = useState('')
    const shortCodeCapturedText = useRef(new BehaviorSubject<string>('')) // if you don't useRef you get a new one on every render and it keeps resetting

    const orderNumberErrorLabel = useRef<ErrorLabelRef>(null);
    const [scannerResult, setScannerResult] = useState('')
    const [scannerWasCancelled, setScannerWasCancelled] = useState('')
    const [showScanner, setShowScanner] = useState(false)

    const checkOrderForErrors = (model: CheckInOrderModel): { errorMessage: Option<string> } => {
        if (model.eventId !== eventId) return {errorMessage: some('Order returned is for a different event')}
        return {errorMessage: none}
    }

    if (showScanner) {
        window.cordova.plugins.barcodeScanner.scan(
            (result: any) => {
                console.log(result)
                setShowScanner(false)
                setScannerResult(result.text)
                setScannerWasCancelled(result.cancelled)
            },
            (err: any) => console.error(err),
            {
                //preferFrontCamera: true, // iOS and Android
                showFlipCameraButton: true, // iOS and Android
                showTorchButton: true, // iOS and Android
                torchOn: false, // Android, launch with the torch switched on (if available)
                saveHistory: true, // Android, save scan history (default false)
                prompt: "Place a barcode inside the scan area", // Android
                resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                //formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
                //formats:'all',
                orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                disableAnimations: true, // iOS
                disableSuccessBeep: false // iOS and Android
            }
        )
    }


    // In hindsight, could probably have just use setState for these, not rx.  
    shortCodeCapturedText.current.subscribe(x => {
        if (!!orderNumberErrorLabel.current) orderNumberErrorLabel.current.setVisible(false)
        
        if (x.trim() !== orderNumber) {
            setOrderNumber(x)
        }
    })
    const mode = isSome(orderModel) ? CheckInScannerPageMode.ShowingOrder : CheckInScannerPageMode.NotShowingOrder
    const reset = () => {
        setScannerResult('')
        shortCodeCapturedText.current.next('')
        setOrderModel(none)
        setIsLoading(false)
    }
    
    const defaultPerson:SearchTicketCheckInModel = {    ticketId: '',
        ticketTypeId: '',
        checkedIn: false,
        ticketTypeName: '',
        displayString: '',
        orderNumber: 0,
        rowNumber: 0,
        ticketNumber: '',
        emailOnOrder: '',
        ticketCancelled:false} // to do... need to populate for the individual search query (maybe... check) 

    // scanner
    useEffect(() => {
        console.log('processing scanner', scannerResult)
        
        if (scannerResult.trim() === '') return
        //setIsLoading(true)
        const sub = AxiosRequest$(activeEnvironment, scannerResult, GetCheckinOrderFromQrCode, some(dispatch))
            .subscribe({
                next: next => {
                    const check = checkOrderForErrors(next)
                    setGlobalError(check.errorMessage) // both are Options
                    if (isNone(check.errorMessage))
                        setOrderModel(some(next))
                    else
                        setOrderModel(none)
                },
                error: e => {
                    setGlobalError(some(e))
                    setOrderModel(none)
                    setIsLoading(false) 
                },
                complete: () => {
                    setIsLoading(false)
                }
            })

        return () => {
            sub.unsubscribe()
        };
    }, [scannerResult])

    // order number
    useEffect(() => {
        
        setGlobalError(none)
        if (orderNumber.trim() === '') return
        setIsLoading(true)
        const sub = AxiosRequest$(activeEnvironment, orderNumber, GetCheckinOrderFromOrderNumber, some(dispatch))
            .subscribe({
                next: next => {
                    const check = checkOrderForErrors(next)
                    if (isNone(check.errorMessage))
                        setOrderModel(some(next))
                    else
                        setOrderModel(none)

                    setIsLoading(false)
                },
                error: e => {
                    setIsLoading(false) // don't know why... but the complete handler didn't catch it.
                    orderNumberErrorLabel.current!.setVisible(true)
                    orderNumberErrorLabel.current!.setText(`Unable to find order ${orderNumber}`)
 
                },
                complete: () => {
                    setIsLoading(false)
                }
            })

        return () => {
            sub.unsubscribe()
        };
    }, [orderNumber])


    return <IonContent>
        <IonHeader collapse={undefined}>
            {/*<IonToolbar>*/}
            {/*    <IonTitle size="large">Check In</IonTitle>*/}
            {/*</IonToolbar>*/}
        </IonHeader>
        {isSome(globalError) && <>
            <IonCard margin-top className="ion-padding ion-margin">
                <IonCardContent>
                    {globalError.value}
                </IonCardContent>
                <IonButton style={{width: '100%'}} onClick={() => {
                    setGlobalError(none)
                }} color={'tertiary'}>
                    <IonIcon icon={scan}/>
                    <span className="ion-padding-start">Scan Again</span>
                </IonButton>
            </IonCard>


        </>}
        {isNone(globalError) && mode === CheckInScannerPageMode.ShowingOrder && <div className="ion-padding">

            <p>
                <IonButton style={{width: '100%'}} onClick={reset} color={'primary'}>
                    <IonIcon icon={search}/>
                    <span className="ion-padding-start">Scan Again</span>
                </IonButton>
            </p>
            {/*<p>*/}
            {/*    <IonButton style={{width: '100%'}} onClick={reset} color={'secondary'}>*/}
            {/*        <IonIcon icon={search}/>*/}
            {/*        <span className="ion-padding-start">Check In All</span>*/}
            {/*    </IonButton>*/}
            {/*</p>*/}

            {isSome(orderModel) &&
            <>
                <h1>{orderModel.value.eventName}</h1>
                <h1>Order: {orderModel.value.orderNumber}</h1>

                {orderModel.value.tickets.map(t => <>

                    <CheckInCard2 key={`${activeEnvironment.selectedCompany}_${t.ticketId}`} 
                                 person={{...defaultPerson, checkedIn:t.checkedIn, ticketId:t.ticketId, ticketNumber:t.ticketNumber, displayString:t.displayString, ticketTypeName:t.ticketTypeName, ticketCancelled:t.ticketCancelled}} 
                                 activeEnvironment={activeEnvironment}/>
                </>)}
            </>
            }

        </div>}

        {isNone(globalError) && mode === CheckInScannerPageMode.NotShowingOrder && <div className="ion-padding">

            <IonButton style={{width: '100%'}} onClick={() => {
                setShowScanner(true)
            }} color={'primary'}>
                <IonIcon icon={scan}/>
                <span className="ion-padding-start">Scan QR Code</span>
            </IonButton>


            

            {isDeveloperMode && <IonButton style={{width: '100%'}} onClick={() => {
                shortCodeCapturedText.current.next('77563')
            }} color={'secondary'}>
                <span className="ion-padding-start">Load Temp Order</span>
            </IonButton>}
            
            
            {isDeveloperMode && <IonButton style={{width: '100%'}} onClick={() => {
                setScannerResult('Ydbjrru8ZEi_JxONL-OIEQ')
            }} color={'secondary'}>
                <span className="ion-padding-start">Test Scan Fake BarCode</span>
            </IonButton>}

            {isDeveloperMode && <>
                <h2>Or</h2>
                <p>
                <IonLabel position="floating" color="primary">Enter Order Number</IonLabel>
                <IonInput name="shortCode" type="text" spellCheck={false} autocapitalize="off" onIonChange={e => shortCodeCapturedText.current.next(e.detail.value!)}/>
            </p>

                <OrderNumberErrorLabel ref={orderNumberErrorLabel}/></>
            }
            
        </div>
        
        }
        {isLoading && <IonProgressBar type="indeterminate"/>}

    </IonContent>
}

