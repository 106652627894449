import {sum} from 'lodash'
import {IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonProgressBar, IonTitle, IonToolbar} from "@ionic/react";
import {isSome, none, Option, some,fromNullable} from "fp-ts/Option";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {PageName, PageSettings} from "../../app/ticketsCore.pageSettings";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {SearchTicketsParams} from "../../data/ticketsAPI/Models/EventModels";
import {TicketsCheckedInPhoneModel, TicketSoldPhoneModel} from "../../data/ticketsAPI/Models/TicketsSoldModel";
import {AxiosRequest$} from "../../data/user/tickets-auth-api";
import {GetTicketsCheckedIn, GetTicketsSold} from "../../data/user/tickets-http-requests";

type checkinProps = { eventId: string, activeEnvironment: EnvironmentSettings }
export const EventSalesDetails = ({eventId, activeEnvironment}: checkinProps) => {
    const history = useHistory()
    const pageSize = 10
    console.log('event list')
    const ios = useAppSelector(x => x.settingsSlice.mode) === 'ios';
    const searchParams: SearchTicketsParams = {"pageSize": pageSize, "pageIndex": 1, "searchTerm": "", eventId: eventId, searchOnNameOnly: true, showCheckedIn: true, showNotCheckedIn: true, ticketTypeIds: []}
    const [isLoading, setIsLoading] = useState(true)
    const [time, setTime] = useState('')
    const [error, setError] = useState<Option<string>>(none)
    const [ticketsCheckedInModel, setTicketsCheckedInModel] = useState<Option<TicketsCheckedInPhoneModel>>(none)
    const dispatch = useAppDispatch()
    const now = new Date()


    useEffect(function () {
        if (isSome(error)) return
        setTime(`in use effect ${now.getUTCHours()} ${now.getUTCMinutes()} ${now.getUTCSeconds()} ${now.getUTCMilliseconds()}`)
        const sub = AxiosRequest$(activeEnvironment, eventId, GetTicketsCheckedIn, some(dispatch))
            .subscribe({
                next: next => {
                    setTicketsCheckedInModel(x => fromNullable(next))
                },
                error: e => {
                    setError(some(e))
                },
                complete: () => {
                    setIsLoading(false)
                }
            })

        return () => {
            sub.unsubscribe()
        };
    }, [activeEnvironment, eventId, error])


    const content = useRef<HTMLIonContentElement>(null) //https://linguinecode.com/post/how-to-use-react-useref-with-typescript
    const homePage = PageSettings[PageName.events]

    if (isSome(ticketsCheckedInModel))
        return <IonContent>
            <h3 className='ion-padding-start ion-text-center'>{ticketsCheckedInModel.value.eventName}</h3>
            <h3 className='ion-padding-start ion-text-center'>{ticketsCheckedInModel.value.startDate}</h3>
            <h6 className='ion-padding-start ion-padding-top'>Total Attendees: {sum(ticketsCheckedInModel.value.items.map(x=> x.checkedIn + x.notCheckedIn))} </h6>
            <h6 className='ion-padding-start'>Checked In: {sum(ticketsCheckedInModel.value.items.map(x=> x.checkedIn))} </h6>
            <h6 className='ion-padding-start'>Not Checked In: {sum(ticketsCheckedInModel.value.items.map(x=> x.notCheckedIn))} </h6>
            
            {ticketsCheckedInModel.value.items.map(x =>
                <IonCard key={x.ticketId}>
                    <IonCardHeader>
                        <IonCardTitle>
                            {x.name}
                        </IonCardTitle>
                        <IonCardSubtitle>Checked In: {x.checkedIn}</IonCardSubtitle>
                        <IonCardSubtitle>Remaining: { x.notCheckedIn}</IonCardSubtitle>
                        <IonCardSubtitle>Total: {x.checkedIn + x.notCheckedIn}</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            )}

            {isLoading && <IonProgressBar type="indeterminate"/>}
        </IonContent>
    else
        return <IonContent>
            {isLoading && <div className='ion-margin'>
                <p >Getting Check In Details</p>
                <IonProgressBar  type="indeterminate"/></div> }
            </IonContent>
}


// <IonCard key={x.companyId}
//          onClick={(e) => dispatch1(x.companyId)}>
//
//     <IonCardHeader>
//         <IonCardTitle> {x.companyName}</IonCardTitle>
//         {/*<IonCardSubtitle> <IonIcon icon={briefcase}/> Could possibly put something here like a summary of your access level</IonCardSubtitle>*/}
//     </IonCardHeader>
//
//
// </IonCard>