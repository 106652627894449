import {IonApp, IonSplitPane, getConfig} from '@ionic/react'
import {IonReactRouter} from '@ionic/react-router'
import Menu from './components/Menu'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import {useAppDispatch, useAppSelector} from './app/hooks'
import * as core from "./app/ticketsCore"
import React, {useEffect, useState} from "react"
import {setDarkMode} from "./features/CommonActions/SettingsAndStorageActions";
import {useIsLoggedIn} from "./features/Login/UseIsLoggedIn"
import {phoneModes, setPhoneMode} from "./features/Settings/settingsSlice";
import {Routes} from "./Routes"
import {ClearDataBaseOnToken, RunSetup} from "./app/startup"
import {loadStateFromStorage} from "./features/Storage/storageSlice"

const App: React.FC = () => {

    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useAppDispatch()
    const storageInitialised = useAppSelector(x => x.storageSlice.storageInitialised)
    const environment = useAppSelector(x => x.loginSlice.activeEnvironment)

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // Listen for changes to the prefers-color-scheme media query
    function toggleDarkTheme(darkMode:boolean) {
        document.body.classList.toggle('dark-theme', darkMode);
        darkMode ? dispatch(setDarkMode('dark')) : dispatch(setDarkMode('light'))
    }
    prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));
    
    
    useEffect(() => {
        RunSetup().then((x) => {
            core.Singleton.storage = x.storage
            setIsLoading(false)
            return x.storage
        })
            .then(x => {
                return ClearDataBaseOnToken(x)
            })
    }, [])

    if (!isLoading && !storageInitialised)
        dispatch(loadStateFromStorage())

    //const config = getConfig()

    const isLoggedIn = useIsLoggedIn()
    // console.log(`running APP loggedOn: ${isLoggedIn}`)
    const darkMode = useAppSelector(x => x.settingsSlice.darkMode)
    //let dispatch = useAppDispatch()
    let mode = getConfig()?.get('mode') || 'md' as phoneModes
    dispatch(setPhoneMode(mode))

    return (
        isLoading || !storageInitialised
            ? <div>Loading...</div>
            : <IonApp className={darkMode === 'dark' ? 'dark-theme' : ''}>
                <IonReactRouter>
                    <IonSplitPane contentId="main">
                        <Menu isLoggedIn={isLoggedIn} environment={environment}/>
                        <Routes isLoggedIn={isLoggedIn}/>
                    </IonSplitPane>
                </IonReactRouter>
            </IonApp>
    )
}


export default App
