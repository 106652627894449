import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonList, IonSelect, IonSelectOption} from "@ionic/react";
import {pipe} from "fp-ts/function";
import {fromNullable} from "fp-ts/Option";
import * as O from "fp-ts/Option";
import {briefcase, moonOutline} from "ionicons/icons";
import React, {MouseEventHandler, useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setCompanyId} from "../CommonActions/SettingsAndStorageActions";

export const CompanySelectPage: React.FC = (b) => {
    let dispatch = useAppDispatch()
    let [forceRedirect, setforceRedirect] = useState(false)
    //const storageInitialised = useAppSelector(x => x.storageSlice.storageInitialised) 
    const companies = useAppSelector(x => pipe(x.loginSlice.activeUserDetails, O.map(x => x.companyDetails), O.match(() => [], a => a)))
    const history = useHistory()
    if (forceRedirect) {
        history.push('/page/Home')
        setforceRedirect(false)
    }

    let dispatch1 = (companyId: string) => {
        dispatch(setCompanyId({companyId: fromNullable(companyId)}));
        setforceRedirect(true)

    }
    return <>
        {companies.map(x => (
            <IonCard key={x.companyId}
                     onClick={(e) => dispatch1(x.companyId)}>

                <IonCardHeader>
                    <IonCardTitle> {x.companyName}</IonCardTitle>
                    {/*<IonCardSubtitle> <IonIcon icon={briefcase}/> Could possibly put something here like a summary of your access level</IonCardSubtitle>*/}
                </IonCardHeader>


            </IonCard>

        ))}

    </>
}