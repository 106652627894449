// https://www.typescriptlang.org/docs/handbook/modules.html
import {Storage} from '@ionic/storage';
import {pipe} from "fp-ts/function";
import {fromNullable, none, Option} from "fp-ts/Option";
import * as O from "fp-ts/Option";
import {Environment} from "./environmentFunctions";
import {EnumDictionary, EnvironmentSettings} from "./ticketsCore.Tooling";

export const nameof = <T>(name: keyof T) => name; // ... not quite what you think https://schneidenbach.gitbooks.io/typescript-cookbook/content/nameof-operator.html
export const storageKey = 'ticketsStore'

export const TestSettings = {
    RunIntegratorTests: true,
}
/** optionally Runs API tests */
export const itIfAPI = () => TestSettings.RunIntegratorTests ? it : it.skip;

export type TicketsSingletons =
    {
        storage: Storage
    }

export let Singleton: TicketsSingletons = {storage: null!}!

export enum LoggedInStatus {
    loggedOut,
    loggedIn
}


export const ValueOrEmpty = (o: Option<string>) => pipe(o, O.match(() => '', b => b)) 