import {fromNullable, isNone} from "fp-ts/Option";
import {phoneModes, setPhoneMode} from "../features/Settings/settingsSlice";
import {useAppDispatch} from "./hooks";
import {TicketsSingletons} from "./ticketsCore";
import {Drivers, Storage} from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {getConfig} from '@ionic/react'

const currentDbVersion = '65AD52F1-C470-4013-BF01-77DF3940B62F'

export async function ClearDataBaseOnToken(storage: Storage)
{
    const k = 'dbVersion'
    const current = fromNullable(await storage.get(k))
    if (isNone(current) || current.value !== currentDbVersion)
    {
        await storage.clear()
        await storage.set(k, currentDbVersion)
    }
}

export async function RunSetup(): Promise<TicketsSingletons> {
    
    let result: TicketsSingletons = {
        storage: new Storage({
            name: '__tickets_org_db',
            driverOrder: [CordovaSQLiteDriver._driver,Drivers.IndexedDB, Drivers.LocalStorage]
        })
    }
    try {
        
        
        result.storage = await result.storage.create()

    } catch (error) {
        console.log('ERROR!!!!', error)
    }
    return result


    //Singletons = result

    // to do... put a catch?

    //SplashScreen.hide().finally();
    //axios.defaults.adapter = require('axios/lib/adapters/http');


    /// perhaps don't use redux to load initial state?


}

