import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonSearchbar, IonTitle, IonToolbar} from '@ionic/react';
import {search} from "ionicons/icons";
import React, {useRef} from "react";
import {useHistory} from "react-router-dom";
import * as ps from "../app/ticketsCore.pageSettings";
import {useIsLoggedIn} from "../features/Login/UseIsLoggedIn";
import './Page.css';


type PageProps = { pageName: string }

//const Page: React.FC<PageProps> = () => {
export const Page = ({pageName}: PageProps) => {
    const pageSettings = ps.PageSettings[pageName as ps.PageName]
    const isLoggedIn = useIsLoggedIn()
    const content = useRef<HTMLIonContentElement>(null)
    const history = useHistory() // typing from https://stackoverflow.com/questions/49342390/typescript-how-to-add-type-check-for-history-object-in-react

    if (!isLoggedIn && pageSettings.isSecure)
        history.push('/page/Login')
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{pageSettings.pageName}</IonTitle>
                </IonToolbar>
            </IonHeader>

            {pageSettings.defaultPageTemplate
                ? <IonContent ref={content} scrollEvents={false} fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">{pageSettings.pageName}</IonTitle>
                        </IonToolbar>
                    </IonHeader>


                    <div className="ion-padding">
                        <pageSettings.$Template/>
                    </div>
                </IonContent>
                : <pageSettings.$Template/>
            }
        </IonPage>
    );
};

export default Page;
