import * as TE from "fp-ts/TaskEither";
import axios from "axios";
import {ArrayEmpty, arrayToUriParamString} from "../../app/Services/ArrayFunctions";
import {CheckInOrderModel} from "../ticketsAPI/Models/CheckInOrderModels";
import {EventHeaderModel, SearchEventsParams, SearchTicketCheckInModel, SearchTicketsParams} from "../ticketsAPI/Models/EventModels";
import {TicketsCheckedInPhoneModel, TicketSoldPhoneModel} from "../ticketsAPI/Models/TicketsSoldModel";
import {AxiosError, TicketsAPI, ticketsQuery, onRejected, onRejectedSimple} from "./tickets-auth-api";
import {UserDetails} from "../ticketsAPI/Models/userDetails";

export const GetBearerToken: ticketsQuery<string, string> = (e: TicketsAPI, shortCode: string) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/apisecuritytokens/GetBearerFromToken?shorttoken=${shortCode}`, e.axiosConfig).then(r => r.data.value), onRejected)

export type GetBearerTokenWithDetailPayload = {shortCode: string, historicEmails:string[]}
export const GetBearerTokenWithDetail: ticketsQuery<GetBearerTokenWithDetailPayload, UserDetails> = (e: TicketsAPI, s:GetBearerTokenWithDetailPayload) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/apisecuritytokens/GetBearerAndDetailsFromToken?shorttoken=${s.shortCode}&${arrayToUriParamString(s.historicEmails, 'historicEmail')}`, e.axiosConfig).then(r => r.data), onRejected)

export const RequestShortCodeToEmail: ticketsQuery<string, void> = (e: TicketsAPI, emailAddress: string) =>
    TE.tryCatch(() => axios.post(`${e.environmentSettings.baseUrl}/phoneapi/apisecuritytokens/SendShortTokenToEmail?email=${emailAddress}`, e.axiosConfig).then(r => r.data), onRejected)

export const GetEnvironmentDetails: ticketsQuery<void, string> = (e: TicketsAPI) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/PhoneAPISamples/GetEnvironmentDetails`, e.axiosConfig).then(r => r.data), onRejected)


export const SearchEventHeaders: ticketsQuery<SearchEventsParams, EventHeaderModel[]> = (e: TicketsAPI, data) =>
    TE.tryCatch(() => axios.post(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/SearchEventHeaders`,data, e.axiosConfig).then(r => r.data), onRejected)

export type TicketTypes = {ticketTypeId:string, ticketName:String}
export const GetTicketTypeIds: ticketsQuery<{eventId:string}, TicketTypes[]> = (e: TicketsAPI, data) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/GetTicketTypeIds?eventId=${data.eventId}`, e.axiosConfig).then(r => r.data), onRejected)

export const SearchTickets: ticketsQuery<SearchTicketsParams, SearchTicketCheckInModel[]> = (e: TicketsAPI, data) =>
    TE.tryCatch(() => axios.post(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/SearchTickets`,data, e.axiosConfig).then(r => r.data), onRejected)


export type CheckInTicketParam = {ticketId:string, checkedIn:boolean}
export const CheckInTicket: ticketsQuery<CheckInTicketParam, {status:boolean}> = (e: TicketsAPI, data) =>
    TE.tryCatch(() => axios.post(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/CheckInticket?ticketId=${data.ticketId}&checkedIn=${data.checkedIn ? 'true' : 'false'}`,null, e.axiosConfig).then(r => r.data), onRejected)


export const GetCheckinOrderFromOrderNumber: ticketsQuery<string, CheckInOrderModel> = (e: TicketsAPI, orderNumber) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/OrderCheckInDetailsFromOrderNumber?ordernumber=${orderNumber}`,e.axiosConfig).then(r => r.data), onRejected)

export const GetCheckinOrderFromQrCode: ticketsQuery<string, CheckInOrderModel> = (e: TicketsAPI, qrCode) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/GetOrderDetailsFromQRCode?qrcode=${qrCode}`,e.axiosConfig).then(r => r.data), onRejected)

export const GetTicketsSold: ticketsQuery<string, TicketSoldPhoneModel> = (e: TicketsAPI, eventId) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/GetTicketsSold?eventId=${eventId}`,e.axiosConfig).then(r => r.data), onRejected)

export const GetTicketsCheckedIn: ticketsQuery<string, TicketsCheckedInPhoneModel> = (e: TicketsAPI, eventId) =>
    TE.tryCatch(() => axios.get(`${e.environmentSettings.baseUrl}/phoneapi/eventsapi/GetTicketsCheckedIn?eventId=${eventId}`,e.axiosConfig).then(r => r.data), onRejected)

