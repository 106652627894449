import {menuController, ScrollDetail, SearchbarChangeEventDetail} from "@ionic/core";
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonProgressBar, IonSearchbar, IonTitle, IonToggle, IonToolbar} from "@ionic/react";
import {isSome, none, Option, some} from "fp-ts/Option";
import {arrowBackCircle, moonOutline, people, search} from "ionicons/icons";
import _, {debounce} from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {mergeMap} from "rxjs/operators";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {PageName, PageSettings} from "../../app/ticketsCore.pageSettings";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {SearchTicketCheckInModel, SearchTicketsParams} from "../../data/ticketsAPI/Models/EventModels";
import {AxiosRequest$} from "../../data/user/tickets-auth-api";
import {GetTicketTypeIds, SearchTickets} from "../../data/user/tickets-http-requests";
import {setDarkMode} from "../CommonActions/SettingsAndStorageActions";
import {CheckInCard} from "./CheckInCard";
import {CheckInCard2} from "./CheckInCard2";

type checkinProps = { eventId: string, activeEnvironment: EnvironmentSettings }
export const CheckInList = ({eventId, activeEnvironment}: checkinProps) => {
    const history = useHistory()
    const pageSize = 10
    console.log('event list')
    const ios = useAppSelector(x => x.settingsSlice.mode) === 'ios';
    
    const [isLoading, setIsLoading] = useState(true)
    //const [searchText, setSearchText] = useState('')
    const [allLoaded, setAllLoaded] = useState(false)
    const [error, setError] = useState<Option<string>>(none)
    const [includePeopleCheckedIn, setIncludePeopleCheckedIn] = useState(true)
    const searchParams: SearchTicketsParams = {"pageSize": pageSize, "pageIndex": 1, "searchTerm": "", eventId: eventId, searchOnNameOnly: true, showCheckedIn: includePeopleCheckedIn, showNotCheckedIn: true, ticketTypeIds: []}
    const [responseEventHeaders, setResponseEventHeaders] = useState<SearchTicketCheckInModel[]>([])
    const dispatch = useAppDispatch()
    const [requestPayload, setRequestPayload] = useState(searchParams)
    const [lastPageLoaded, setLastPageLoaded] = useState(0)
    const content = useRef<HTMLIonContentElement>(null) //https://linguinecode.com/post/how-to-use-react-useref-with-typescript
    const homePage = PageSettings[PageName.events]


    const toggleShowCheckiedIn = () => {
        reset()
        setIncludePeopleCheckedIn(x=>!x)
        setRequestPayload(x => ({...x, showCheckedIn:!x.showCheckedIn, pageIndex: 1}))
    }
    
    function reset() {
        setResponseEventHeaders([])
        setIsLoading(true)
        setLastPageLoaded(1)
        setAllLoaded(false)
    }

    let searchText = (e: CustomEvent<SearchbarChangeEventDetail>) => {
        reset()
        setRequestPayload(e2 => ({...e2, searchTerm: e.detail.value!, pageIndex: 1}))
    }

    const trackScrollInternal = debounce(async (e: CustomEvent<ScrollDetail>) => {
        //https://stackoverflow.com/questions/61886891/how-to-get-maximum-scroll-height-for-ion-content
        const elem = content.current!
        const scrollElement = await (elem as any).getScrollElement()
        const scrollPosition = e.detail.scrollTop;
        const totalContentHeight = scrollElement.scrollHeight;
        const viewportHeight = elem.getBoundingClientRect().top + elem.offsetHeight; // I think that elem.ClientHeight returns the same
        const percentage = scrollPosition / (totalContentHeight - viewportHeight);
        const distanceFromBottom = (totalContentHeight - viewportHeight) - scrollPosition

        if (distanceFromBottom < 1000 && lastPageLoaded === requestPayload.pageIndex && !allLoaded) {
            setIsLoading(true)
            setRequestPayload(e => ({...e, pageIndex: e.pageIndex + 1}))
        }
    }, 100)


    useEffect(function () {
        if (isSome(error)) return
        console.log('i will be there', includePeopleCheckedIn, requestPayload)
        const sub = AxiosRequest$(activeEnvironment, {eventId: eventId}, GetTicketTypeIds, some(dispatch))
            .pipe(mergeMap(x => AxiosRequest$(activeEnvironment, {...requestPayload, ticketTypeIds: _.map(x, x2 => x2.ticketTypeId)}, SearchTickets, some(dispatch))))
            .subscribe({
                next: next => {
                    setResponseEventHeaders(x => [...x, ...next])
                    if (next.length < requestPayload.pageSize) setAllLoaded(true)
                    setLastPageLoaded(requestPayload.pageIndex)
                },
                error: e => {
                    setError(some(e))
                },
                complete: () => {
                    setIsLoading(false)
                }
            })

        return () => {
            sub.unsubscribe()
        };
    }, [requestPayload, activeEnvironment, eventId, error])

    return <>
        <IonSearchbar className='no-padding' onIonChange={e => searchText(e)}/>
        <IonItem lines="none">
            <IonIcon slot="start" icon={people}/>
            <IonLabel onClick={() => toggleShowCheckiedIn()}>Include People Checked In </IonLabel>
            <IonToggle content-id='mytoggle' checked={includePeopleCheckedIn} onClick={() => toggleShowCheckiedIn()}/>
        </IonItem>
        
        
        <IonContent ref={content} scrollEvents={true} onIonScroll={(e) => trackScrollInternal(e)} className="">
            {/*See https://ionicframework.com/docs/api/header for details on passing undefined vs 'collapse'. This affects the headings.  */}
            <IonHeader collapse={undefined}>
                <IonToolbar>
                    <IonTitle>Check In</IonTitle>
                </IonToolbar>
            </IonHeader>
            <div className="ion-padding">
                {isSome(error)
                    ? <div>{error.value}</div>
                    : (<>
                        {responseEventHeaders.map(e => (<CheckInCard2 key={`${activeEnvironment.selectedCompany}_${e.ticketId}`} person={e} activeEnvironment={activeEnvironment}/>))}
                        {isLoading && <IonProgressBar type="indeterminate"/>}

                    </>)}
            </div>
        </IonContent>
    </>
}

