import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {pipe as fptspipe} from "fp-ts/function";
import * as O from "fp-ts/Option";
import {fromNullable, isNone, isSome, none, Option, some} from "fp-ts/Option";
import {ofType, StateObservable} from "redux-observable";
import * as rxjs from "rxjs"
import {interval, Observable, pipe} from "rxjs"
import {bufferCount, bufferToggle, catchError, endWith, filter, mapTo, mergeMap, raceWith, startWith, switchMap} from "rxjs/operators";
import {v4 as uuidv4} from 'uuid';
import {ClearEnvironmentOfUserData, Environment, GetEnvironmentSettings, initialEnvironment, MergeEnvironmentWithUserData} from "../../app/environmentFunctions";
import {limitedArrayProxy} from "../../app/Services/ArrayFunctions";
import {RootState} from "../../app/store";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {UserDetails} from "../../data/ticketsAPI/Models/userDetails";
import {AxiosRequest$} from "../../data/user/tickets-auth-api";
import {GetBearerTokenWithDetail, GetBearerTokenWithDetailPayload} from "../../data/user/tickets-http-requests";
import {DictionaryOfOptions, processUserDetailsFromServer, setCompanyId, setEnvironment, stateRetrievedFromStorage, StoredData} from "../CommonActions/SettingsAndStorageActions";

export enum shortCodeLoadingStates {loading, notLoading}

export interface LoginState {
    userDetails: DictionaryOfOptions<UserDetails>,
    selectedCompaniesPerEnvironment: DictionaryOfOptions<string>
    activeEnvironment: EnvironmentSettings,
    /**stores the selected company id for a given environment*/
    activeUserDetails: Option<UserDetails>,
    shortCodeLoadingState: shortCodeLoadingStates,
    developerModeEnabled: boolean,
    companyCountForUser:number,
    // set a uuid when they log in. This can be used as keys to render components so that, when they log in and out, React refreshes all the components
    // !!! Didn't end up actually using this in anger... but leave it here because might come in handy for something. 
    loginSessionId: string,
    recentlyTriedEmails: string[]
}

const minShortCodeLength = 1 // need to keep this is sync with the back end. Will use so that they don't have to press enter. Search for CreateShortToken() in c# 

export const initialState: LoginState = {
    userDetails: {
        [Environment.production]: fromNullable(null),
        [Environment.development]: fromNullable(null),
        [Environment.local]: fromNullable(null),
        [Environment.localFiddler]: fromNullable(null)
    },
    selectedCompaniesPerEnvironment: {
        [Environment.production]: fromNullable(null),
        [Environment.development]: fromNullable(null),
        [Environment.local]: fromNullable(null),
        [Environment.localFiddler]: fromNullable(null)
    },
    activeEnvironment: GetEnvironmentSettings[initialEnvironment],
    activeUserDetails: none,
    shortCodeLoadingState: shortCodeLoadingStates.notLoading,
    loginSessionId: uuidv4(),
    developerModeEnabled: false,
    companyCountForUser:0,
    recentlyTriedEmails: []

};
export const LoginSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        tapLogoForSecretCode: (state, action: PayloadAction<void>) => {
        },
        toggleDeveloperMode: (state, action: PayloadAction<void>) => {
            state.developerModeEnabled = !state.developerModeEnabled
        },
        requestShortCodeToEmail: (state, action: PayloadAction<string>) => {
            let t = new Proxy( state.recentlyTriedEmails, limitedArrayProxy(10))
            if (!t.find((x:string)=>x===action.payload)) {
                t.unshift(action.payload)
                state.recentlyTriedEmails = t
            }
        },
        processShortCode: (state, action: PayloadAction<GetBearerTokenWithDetailPayload>) => {
        },
        finishedProcessShortCode: (state) => {
            state.shortCodeLoadingState = shortCodeLoadingStates.notLoading
        },
        startProcessShortCode: (state) => {
            state.shortCodeLoadingState = shortCodeLoadingStates.loading
        }
    },
    extraReducers: (builder) => {

        builder.addCase(setEnvironment, (state, action: PayloadAction<Environment>) => {
            console.log(`setting environment: selectedCompanies`, current(state.selectedCompaniesPerEnvironment))
            state.activeEnvironment = MergeEnvironmentWithUserData(state.userDetails, state.selectedCompaniesPerEnvironment, action.payload)
            state.activeUserDetails = state.userDetails[action.payload]
            state.companyCountForUser = fptspipe(state.activeUserDetails, O.match(() => [], ud => ud.companyDetails)).length

            // The line below didn't work: https://stackoverflow.com/a/54413951/494635
            //state.activeEnvironment.bearerToken = state.bearerTokens[action.payload]
            // use this instead
            // //state.activeEnvironment = {...core.GetEnvironmentSettings[action.payload], bearerToken: pipe(state.userDetails[action.payload], O.map(pd => pd.bearerToken))}

        })
        builder.addCase(setCompanyId, (state, action: PayloadAction<{ companyId: Option<string> }>) => {
            console.log(`setting environment: selectedCompanies`, current(state.selectedCompaniesPerEnvironment))
            state.selectedCompaniesPerEnvironment[state.activeEnvironment.environment] = action.payload.companyId
            state.activeEnvironment = MergeEnvironmentWithUserData(state.userDetails, state.selectedCompaniesPerEnvironment, state.activeEnvironment.environment)
        })

        builder.addCase(processUserDetailsFromServer, (state, action: PayloadAction<{ userData: Option<UserDetails>, environment: Environment }>) => {
            let p = action.payload
            const env = action.payload.environment
            state.userDetails[p.environment] = p.userData

            if (isNone(p.userData)) {
                if (state.activeEnvironment.environment === env) {
                    state.activeEnvironment = ClearEnvironmentOfUserData(state.userDetails, env)
                    state.selectedCompaniesPerEnvironment[action.payload.environment] = none
                    state.activeUserDetails = none
                    state.companyCountForUser = 0
                }
            } else {
                state.loginSessionId = uuidv4() // only give them a new id when they get a new session, otherwise you get in an endless loop
                if (state.activeEnvironment.environment === env) {
                    state.selectedCompaniesPerEnvironment[action.payload.environment] = !!p.userData.value.companyDetails ? some(p.userData.value.companyDetails[0]!.companyId) : none
                    state.activeEnvironment = MergeEnvironmentWithUserData(state.userDetails, state.selectedCompaniesPerEnvironment, env)
                    state.activeUserDetails = p.userData
                    state.companyCountForUser = fptspipe(state.activeUserDetails, O.match(() => [], ud => ud.companyDetails)).length

                }
            }
        })

        builder.addCase(stateRetrievedFromStorage, (state, action: PayloadAction<Option<StoredData>>) => {

                if (isSome(action.payload)) {
                    const env = action.payload.value.environment
                    state.userDetails = action.payload.value.userDetails
                    state.selectedCompaniesPerEnvironment = action.payload.value.selectedCompany
                    state.activeUserDetails = state.userDetails[env]
                    state.companyCountForUser = fptspipe(state.activeUserDetails, O.match(() => [], ud => ud.companyDetails)).length
                    state.activeEnvironment = MergeEnvironmentWithUserData(state.userDetails, state.selectedCompaniesPerEnvironment, env)
                    state.recentlyTriedEmails = action.payload.value.recentlyTriedEmails
                }
            }
        )
    }
});

export const convertShortCodeToBearerEpic = (action$: Observable<any>, state$: StateObservable<RootState>) => // action$ is a stream of actions
    action$.pipe(
        ofType(processShortCode),
        filter((x: PayloadAction<GetBearerTokenWithDetailPayload>) => x.payload.shortCode.length >= minShortCodeLength),
        switchMap((x: PayloadAction<GetBearerTokenWithDetailPayload>) =>
            AxiosRequest$(state$.value.loginSlice.activeEnvironment, {shortCode: x.payload.shortCode, historicEmails:x.payload.historicEmails}, GetBearerTokenWithDetail, none)
                .pipe(
                    // https://stackoverflow.com/questions/47965184/how-to-dispatch-multiple-actions-from-redux-observable

                    //map((i: string) => setBearerToken({token: i, environment: (state$).value.loginSlice.activeEnvironment.environment})),
                    mergeMap((i) => [
                            processUserDetailsFromServer({userData: fromNullable(i), environment: (state$).value.loginSlice.activeEnvironment.environment})
                        ]
                    ),
                    catchError(error => rxjs.of(processUserDetailsFromServer({userData: none, environment: (state$).value.loginSlice.activeEnvironment.environment}))),
                    //https://www.gitmemory.com/issue/ReactiveX/rxjs/4772/491553380
                    startWith(startProcessShortCode()), // could probably do something like this to defer emitting until after a second? https://ncjamieson.com/how-to-write-delayuntil/
                    endWith(finishedProcessShortCode()) // Can yield multiple actions as well. //endWith(finishedProcessShortCode(), finishedProcessShortCode()) 
                )
        ))


const tapsToTrigger = 5
export const enableDeveloperModerEpic = (action$: any, state: any) => action$.pipe(
    ofType(tapLogoForSecretCode),
    pipe(
        bufferToggle(action$.pipe(ofType(tapLogoForSecretCode)), // This obs is the start of a window. 
            // This obs triggers the closing of a window. Either after the time has elapsed or we receive the specified number of taps. Note that the subscription for the 'raced' observable is added with each window. 
            () => interval(2000).pipe(raceWith(
                action$.pipe(ofType(toggleDeveloperMode)), // When we actually toggle, then reset all teh buffers. Otherwise, after you toggle, immediately subsequent taps inside the timer window will toggle back.
                action$.pipe(ofType(tapLogoForSecretCode), bufferCount(tapsToTrigger - 1) // -1 because we needed one to trigger it. This is buffering the taps since the window started.   
                ))
            )),
        // after the observable closes, filter on the clicks inside the window which triggered it. 
        filter(x => x.length >= tapsToTrigger)),
    mapTo(toggleDeveloperMode())
);


// Export the actionCreators
export const {startProcessShortCode, finishedProcessShortCode, requestShortCodeToEmail, processShortCode, tapLogoForSecretCode, toggleDeveloperMode} = LoginSlice.actions;
export const epics = [convertShortCodeToBearerEpic, enableDeveloperModerEpic]

// export the reducer
export default LoginSlice.reducer;
