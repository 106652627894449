import {pipe} from "fp-ts/function";
import {fromNullable, none} from "fp-ts/Option";
import * as O from "fp-ts/Option";
import {UserDetails} from "../data/ticketsAPI/Models/userDetails";
import { DictionaryOfOptions} from "../features/CommonActions/SettingsAndStorageActions";
import {EnumDictionary, EnvironmentSettings} from "./ticketsCore.Tooling";
export enum Environment {
    production = "Production",
    development = "Development",
    local = "Localhost",
    localFiddler = "Local Fiddler"
}

export const IsProduction = () => !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
export const initialEnvironment = Environment.production
export const defaultEnvironment: EnvironmentSettings = {
    baseUrl: "",
    environment: Environment.local,
    selectedCompany:none,
    proxy: fromNullable(undefined),
    bearerToken:none,
    handleSelfSignedCerts: false
}

export const GetEnvironmentSettings: EnumDictionary<Environment, EnvironmentSettings> = {
    [Environment.development]: {...defaultEnvironment, environment: Environment.development, baseUrl: 'https://dev.tickets.org.au'},
    [Environment.production]: {...defaultEnvironment, environment: Environment.production, baseUrl: 'https://app.tickets.org.au'},
    [Environment.local]: {...defaultEnvironment, environment: Environment.local, baseUrl: 'https://welcomemat.com', handleSelfSignedCerts: false},
    [Environment.localFiddler]: {...defaultEnvironment, environment: Environment.localFiddler, baseUrl: 'https://welcomemat.com', proxy: fromNullable({port: 8888, host: 'localhost'})},
}

/**Take the data we receive from the server on login and an environment. Will merge the default environment settings with things like bearer tokens that can be used to make API calls */
export const MergeEnvironmentWithUserData= (state: DictionaryOfOptions<UserDetails>,selectedCompanies:DictionaryOfOptions<string>, environment: Environment):EnvironmentSettings =>
    ({
        ...GetEnvironmentSettings[environment],
        bearerToken: pipe(state[environment], O.map(pd => pd.bearerToken)),
        selectedCompany:selectedCompanies[environment]
    })

 export const ClearEnvironmentOfUserData = (state: DictionaryOfOptions<UserDetails>, environment: Environment) =>({...GetEnvironmentSettings[environment]})