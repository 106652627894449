import {join} from 'lodash'
export const  sum = (i:number,j:number) => i + j

// This is crazy cool. "Proxy" Allows modification of an existing object 
// Really interesting!! https://stackoverflow.com/a/53179170/494635
// This is another cool use of proxies https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy#validation
export function limitedArrayProxy (maxLength:number){return {
    set: function(target:any, property:any, value:any, receiver:any) {
        if (!isNaN(property)){
            if(parseInt(property) < maxLength){
                target[property] = value;
            }
        }
        return true;
    }
}}

export const ArrayEmpty = <T>(source:T[]) => (source === undefined || source.length ===0)

export const arrayToUriParamString = (source: string[], paramName:string) =>
    ArrayEmpty(source)  
        ? `${paramName}=[]` 
        : `${paramName}=` + join(source, `&${paramName}=`)

