import {getConfig, IonButton, IonButtons, IonHeader, IonIcon, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar} from "@ionic/react";
import {arrowBackCircle} from "ionicons/icons";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../../app/hooks";
import {PageName, PageSettings} from "../../app/ticketsCore.pageSettings";
import {EventPageModes} from "../Events/EventList";
import {phoneModes} from "../Settings/settingsSlice";
import {CheckInList} from "./CheckInList";
import {CheckInScanner} from "./CheckinScanner";
import {EventSalesDetails} from "./EventSalesDetails";
// https://upmostly.com/tutorials/build-an-infinite-scroll-component-in-react-using-react-hooks
// https://www.digitalocean.com/community/tutorials/react-react-infinite-scroll
type checkinPageProps = { eventId: string }


export enum CheckInPageModes {
    Search = "Search",
    Scan = 'Scan',
    Details= 'Details'
    
    
}

type displayModes = CheckInPageModes.Search | CheckInPageModes.Scan | CheckInPageModes.Details
export const CheckInPage = ({eventId}: checkinPageProps) => {
    const activeEnvironment = useAppSelector(x => x.loginSlice.activeEnvironment)
    const [segment, setSegment] = useState<displayModes>(CheckInPageModes.Scan);
    const history = useHistory()
    let mode = getConfig()?.get('mode') || 'md' as phoneModes

    return <IonPage>
            <IonHeader>
                <IonToolbar>
                    
                    <IonButtons slot="start">
                        <IonButton onClick={() => history.push(`/page/Home`)}>
                            <IonIcon slot="icon-only" icon={arrowBackCircle}/>
                        </IonButton>
                    </IonButtons>
                    {mode == 'md' && <IonTitle className='ion-padding-top'>Check In</IonTitle>}                     
                    <IonSegment value={segment} onIonChange={(e) => {
                        setSegment(e.detail.value as any)
                    }}>
                        <IonSegmentButton value={CheckInPageModes.Search}>
                            {CheckInPageModes.Search}
                        </IonSegmentButton>
                        <IonSegmentButton value={CheckInPageModes.Scan}>
                            {CheckInPageModes.Scan}
                        </IonSegmentButton>
                        <IonSegmentButton value={CheckInPageModes.Details}>
                            {CheckInPageModes.Details}
                        </IonSegmentButton>
                    </IonSegment>
                                        
                </IonToolbar>

                

            </IonHeader>
            {segment === CheckInPageModes.Search && <CheckInList key={`${eventId}`} eventId={eventId} activeEnvironment={activeEnvironment}/>}
            {segment === CheckInPageModes.Scan && <CheckInScanner key={`${eventId}`} eventId={eventId} activeEnvironment={activeEnvironment}/>}
            {segment === CheckInPageModes.Details && <EventSalesDetails key={`${eventId}`} eventId={eventId} activeEnvironment={activeEnvironment} />}
                
        </IonPage>
}

