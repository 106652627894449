// https://welcomemat.com/phoneapi/PhoneAPISamples/getnumbers?count=10 
import axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
import {isRight} from "fp-ts/Either";
import {pipe} from "fp-ts/function";
import * as O from "fp-ts/Option";
import {isSome, none, Option} from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import {TaskEither} from "fp-ts/TaskEither";
import {Observable} from "rxjs";
import {AppDispatch} from "../../app/store";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {processUserDetailsFromServer} from "../../features/CommonActions/SettingsAndStorageActions";

export const onRejected: (reason: unknown) => AxiosError = (r: any) => !!r.response
    ? {status: r.response.status, statusText: r.response.statusText, message:r.message}
    : {code: r.code, message: r.message}

export const onRejectedSimple: (reason: unknown) => AxiosError = (r: any) => r


export interface AxiosErrorWithStatusCode {
    /**The status code returned with the error. Eg 403 */
    status: number,
    statusText: string,
    message : string
}

/** Get these when you get the host wrong etc. */
export interface AxiosErrorWithNoStatusCode {
    code: number,
    message : string
}

/** Type Guard to ascertain error type*/
export function isStatusCodeError(axiosError: AxiosError): axiosError is AxiosErrorWithStatusCode {
    return !!(axiosError as AxiosErrorWithStatusCode).status;
}

export type AxiosError = AxiosErrorWithStatusCode | AxiosErrorWithNoStatusCode
export type ticketsQuery<TProps, TResult> = (e: TicketsAPI, p: TProps) => TaskEither<AxiosError, TResult>

/**Wraps an axios query in an observable. If the Observable is cancelled (eg, by a SwitchMap), then the http request is cancelled too. If you pass an App Disaptch, will issue a logout action if we get 403 from server */
export const AxiosRequest$ = <TProps, TResult>(environmentSettings: EnvironmentSettings, p: TProps, f: ticketsQuery<TProps, TResult>, dispatch: Option<AppDispatch>): Observable<TResult> =>
    new Observable((s) => {
        let api = new TicketsAPI(environmentSettings)
        f(api, p)().then(r => {

            if (isRight(r)) {
                s.next(r.right)
                s.complete()
            } else {
                if (isStatusCodeError(r.left)) {
                    if (r.left.status === 404)
                        s.error(`That item could not be found`)
                    else
                        s.error(`${r.left.message}`)
                    if (r.left.status === 403 && isSome(dispatch))
                        dispatch.value(processUserDetailsFromServer({userData: none, environment: environmentSettings.environment})) // log out

                } else {
                    s.error(`${r.left.message}`)
                }
            }
        })
        return () => {
            api.axiosCancellationSource.cancel()
        };
    });


/**Wraps an axios query in an observable. If the Observable is cancelled (eg, by a SwitchMap), then the http request is cancelled too */
// export const AxiosRequest$ = <TProps, TResult>(environmentSettings: EnvironmentSettings, p: TProps, f: ticketsQuery<TProps, TResult>): Observable<TResult> =>
//     new Observable((s) => {
//         let api = new TicketsAPI(environmentSettings)
//         f(api, p)().then(r => {
//
//             if (isRight(r)) {
//                 s.next(r.right)
//                 s.complete()
//             } else {
//                 if (isStatusCodeError(r.left))
//                     s.error(`${r.left.status} ${r.left.statusText}`)
//                 else
//                     s.error(`error from http call ${r.left.code}`)
//             }
//         })
//         return () => {
//             api.axiosCancellationSource.cancel()
//         };
//     });


export class TicketsAPI {
    public axiosConfig: AxiosRequestConfig;
    public axiosCancellationSource: CancelTokenSource;

    constructor(/**This comes from redux*/ public environmentSettings: EnvironmentSettings) {

        this.axiosCancellationSource = axios.CancelToken.source();
        // can run the Ionic dev as ssl with Ionic serve --https   (https://github.com/ionic-team/ionic-cli/issues/3305#issuecomment-649213731)
        this.axiosConfig = {
            proxy: (isSome(environmentSettings.proxy) && pipe(environmentSettings.proxy, O.match(() => undefined, x => x))), // possibly don't need to pipe if && is short-circuiting
            // see https://github.com/axios/axios/issues/606#issuecomment-269648154
            headers: {
                ...(isSome(environmentSettings.bearerToken) && {Authorization: `Bearer ${environmentSettings.bearerToken.value}`}),
                // I SPENT AN AFTERNOON ON THIS. If you add a custom header here, then you have to add it in web.config under Access-Control-Allow-Headers (and possibly Access-Control-Expose-Headers)
                ...(isSome(environmentSettings.selectedCompany) && {CompanyId: `${environmentSettings.selectedCompany.value}`}),
                // 'Hello':'World',
                // 'Accept-Encoding': 'gzip, deflate, br',
                //  'Sec-Fetch-Mode': 'cors',
                //  'Host': 'localhost:8100'
                // 'Goodbye':'Bugs'

            },
            //httpsAgent: true || environmentSettings.handleSelfSignedCerts ? new Agent({rejectUnauthorized: false}) : new Agent({rejectUnauthorized: false}),
            cancelToken: this.axiosCancellationSource.token

        };
        axios.defaults.adapter = require('axios/lib/adapters/http'); // search for "configure axios adapter" from wallaby support in gmail. https://github.com/axios/axios/issues/1754#issuecomment-572778305 
    }


    public TCExample = (httpCode: number) => TE.tryCatch(() => axios.get(`https://httpstat.us/${httpCode}`), onRejected)
}


//https://dev.tickets.org.au/phoneapi/PhoneAPISamples/GetEnvironmentDetails

 