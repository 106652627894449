import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonRippleEffect} from '@ionic/react';
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {prefixImageHttps, smlToLgeImage} from "../../app/Services/StringFunctions";
import {EventHeaderModel} from "../../data/ticketsAPI/Models/EventModels";
import './LargeCard.css'

type largeCardProps = { event: EventHeaderModel }
export const LargeCard = ({event}: largeCardProps) => {
    const history = useHistory()
    return <>
        <IonCard className="marginBottom card-bg hover" key={event.id} onClick={() => history.push(`/page/Checkin/${event.id}`)}>
            <IonImg src={prefixImageHttps(smlToLgeImage(event.thumbnailUrl))}> </IonImg>
            <IonCardHeader>
                <IonRippleEffect></IonRippleEffect>
                <IonCardSubtitle role="heading">{event.formattedStartDate}</IonCardSubtitle>
                <IonCardTitle role="heading" className="ion-card-title-css{">{event.eventName}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="card-content card-content-ios">
                Tickets Sold:{event.ticketCount}
            </IonCardContent>
        </IonCard>
    </>
}