import React from "react";
import {useAppSelector} from "../../app/hooks";
import {ValueOrEmpty} from "../../app/ticketsCore";
import {EventList} from "../Events/EventList";
// https://upmostly.com/tutorials/build-an-infinite-scroll-component-in-react-using-react-hooks
// https://www.digitalocean.com/community/tutorials/react-react-infinite-scroll

export const HomePage = () => {
    const activeEnvironment = useAppSelector(x => x.loginSlice.activeEnvironment)
    return <EventList key={`${ValueOrEmpty(activeEnvironment.selectedCompany)}`} activeEnvironment={activeEnvironment!}/>
}
