import {isSome, Option, some} from "fp-ts/Option";
import React, {useEffect} from "react";
import {useAppDispatch} from "../../app/hooks";
import {EnvironmentSettings} from "../../app/ticketsCore.Tooling";
import {EventHeaderModel, SearchEventsParams} from "../../data/ticketsAPI/Models/EventModels";
import {AxiosRequest$} from "../../data/user/tickets-auth-api";
import {SearchEventHeaders} from "../../data/user/tickets-http-requests";


type useEventHeaderDataProps = {
    params: SearchEventsParams,
    setResponseEventHeaders: React.Dispatch<React.SetStateAction<EventHeaderModel[]>>,
    setLastPageLoaded: React.Dispatch<React.SetStateAction<number>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setAllLoaded: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<Option<string>>>,
    activeEnvironment: EnvironmentSettings,
    error:Option<string>
}
export const useEventHeaderData = ({params, setResponseEventHeaders, setLastPageLoaded, setAllLoaded, setError,  setIsLoading, activeEnvironment, error}: useEventHeaderDataProps) => {
    const dispatch = useAppDispatch()
    useEffect(function () {
        if (isSome(error)) return
        const sub = AxiosRequest$(activeEnvironment, params, SearchEventHeaders, some(dispatch))
            .subscribe({
                next: next => {
                    setResponseEventHeaders(x=>[...x, ...next])
                    if (next.length < params.pageSize) setAllLoaded(true)
                    setLastPageLoaded(params.pageIndex)
                },
                error: e => {
                    setError(some(e))
                },
                complete: () => {
                    setIsLoading(false)
                }
            })

        return () => {
            sub.unsubscribe()
        };
    }, [params, activeEnvironment, setAllLoaded, setError, setIsLoading, setLastPageLoaded, setResponseEventHeaders, error]) // put these in because of a tslint warning. Not sure if it's right. 
    return useEffect
    
}